/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
@import '~bootstrap/scss/bootstrap';
@import "vue-select/src/scss/vue-select.scss";

@import 'variable'; 
@import 'app';
@import 'pages';
@import 'sidebar';
@import 'icons/font-awesome/css/font-awesome.min.css';
@import 'icons/simple-line-icons/css/simple-line-icons.css';
@import 'icons/weather-icons/css/weather-icons.min.css';
@import 'icons/linea-icons/linea.css';
@import 'icons/themify-icons/themify-icons.css'; 
@import 'icons/flag-icon-css/flag-icon.min.css';
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import 'widgets';
@import 'grid';
@import 'responsive'; 
@import 'material';
@import 'colors/blue';
// @import '../css/spinners.css';
// @import '../css/animate.css'; 

.style-dark {
    background: #000 !important;
}

.style-light {
    background: #fff !important;
}

.login-box {
    width: 500px;
}

img {
    max-width: 100%;
}

.store-list {
    .logo-holder {
        width: 100%;
        height: 200px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}